export const noiteAlema= {
    name: "Noite Alemã",
    slug: "noite-alema",
    description: "Cultura, tradição e culinária típica alemã",
    poi: [
        "cul-alema-serra"
    ],
    opt: [
        [

        ]
    ]
}