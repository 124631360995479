export const transferTranslado= {
    name: "Transfer / Translado",
    slug: "transfer-translado",
    description: "Transporte privativo de/para aeroporto Porto Alegre e Caxias do Sul",
    poi: [
        "AERO-CXJ",
        "AERO-POA"
    ],
    opt: [
        [
            "AERO-CXJ",
            "AERO-POA"
        ]
    ]
}