export const tourAlemaoEComprasNovaPetropolis= {
    name: "Tour Alemão e compras Nova Petrópolis",
    slug: "tour-alemao-e-compras-nova-petropolis",
    description: "",
    poi: [
        "praca-theodor-amstad",
        "armazem-da-rosa-mosqueta",
        "parque-aldeia-do-imigrante",
        "praca-das-flores-e-labirinto-verde",
        "couros-e-malhas"
    ],
    opt: [
        [

        ]
    ]
}