export const tourParques= {
    name: "Tour Parques",
    slug: "tour-parques",
    description: "",
    rules: "Não está incluso no valor os ingressos dos parques e a alimentação.",
    poi: [
        "alpen-park" ,
        "gramado-zoo" ,
        "museu-do-automovel" ,
        "museu-de-cera" ,
        "harley-motor-show" ,
        "fabrica-dos-cristais" ,
        "aldeia-do-papai-noel" ,
        "super-carros" ,
        "terra-magica-florybal"
    ],
    opt: [
        [

        ]
    ]
}