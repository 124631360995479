export const poi = {
    "portico-bavaro": {
        name: "Pórtico Via Nova Petrópolis",
        description: "",
        media: {},
        map: "%20v.%20das%20Hort%C3%AAncias%20-%20Portico%2C%20Gramado%20%20Gramado%20",
        city: ["GRA"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "lago-negro": {
        name: "Lago Negro",
        description: "",
        media: {},
        map: "",
        city: ["GRA"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "mini-mundo": {
        name: "Mini Mundo",
        description: "",
        media: {},
        map: "",
        city: ["GRA"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "lago-joaquina-rita-bier": {
        name: "Lago Joaquina Rita Bier",
        description: "",
        media: {},
        map: "",
        city: ["GRA"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "monumento-kikito": {
        name: "Monumento Kikito",
        description: "",
        media: {},
        map: "",
        city: ["GRA"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "fabricas-de-chocolate": {
        name: "Fábricas de Chocolate",
        description: "",
        media: {},
        map: "",
        city: ["CAN","GRA"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "mundo-a-vapor": {
        name: "Mundo à Vapor",
        description: "",
        media: {},
        map: "",
        city: ["CAN"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "catedral-de-pedra": {
        name: "Catedral de Pedra",
        description: "",
        media: {},
        map: "",
        city: ["CAN"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "igreja-sao-pedro": {
        name: "Igreja São Pedro",
        description: "",
        media: {},
        map: "",
        city: ["GRA"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "cascata-do-caracol": {
        name: "Cascata do Caracol",
        description: "",
        media: {},
        map: "",
        city: ["CAN"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "bondinhos-aereos": {
        name: "Bondinhos Aéreos",
        description: "",
        media: {},
        map: "",
        city: ["CAN"],
        tours: [
            "tour-gramado-canela"
        ]
    },
    "garfo-e-bombacha": {
        name: "Churrascaria Garfo e Bombacha",
        description: "",
        media: {},
        map: "",
        city: ["CAN"],
        tours: [
            "noite-gaucha"
        ]
    },
    "seq-foundue-serra": {
        name: "Sequência de fondue da Serra",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
            "noite-suica"
        ]
    },
    "cul-alema-serra": {
        name: "Restaurantes de culinária típica alemã",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
            "noite-alema"
        ]
    },
    "cul-colonial-serra": {
        name: "Cafés coloniais",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
            "cafe-colonial"
        ]
    },
    "AERO-CXJ": {
        name: "Aeroporto de Caxias do Sul ",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
            "transfer-translado"
        ]
    },
    "AERO-POA": {
        name: "Aeroporto Internacional Salgado Filho",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
            "transfer-translado"
        ]
    },
    "alpen-park" : {
        name: "Alpen Park",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
          "tour-parques"
        ]
    },
    "gramado-zoo" : {
        name: "Gramado zoo",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
          "tour-parques"
        ]
    },
    "museu-do-automovel" : {
        name: "Museu do Automóvel",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
          "tour-parques"
        ]
    },
    "museu-de-cera" : {
        name: "Museu de Cera",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
          "tour-parques"
        ]
    },
    "harley-motor-show" : {
        name: "Harley Motor Show",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
          "tour-parques"
        ]
    },
    "fabrica-dos-cristais" : {
        name: "Fábrica de Cristais",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
          "tour-parques"
        ]
    },
    "aldeia-do-papai-noel" : {
        name: "Aldeia do Papai Noel",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
          "tour-parques"
        ]
    },
    "super-carros" : {
        name: "Super Carros",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
          "tour-parques"
        ]
    },
    "terra-magica-florybal" : {
        name: "Terra Mágica Florybal",
        description: "",
        media: {},
        map: "",
        city: [],
        tours: [
          "tour-parques"
        ]
    },
    "casa-centenaria": {
        name: "Casa Centenária",
        tours: [
            "tour-raizes-coloniais"
        ]
    },
    "moinho-cavichion": {
        name: "Moinho Cavichion",
        tours: [
            "tour-raizes-coloniais"
        ]
    },
    "ervateira-marcon": {
        name: "Ervateira Marcon",
        tours: [
            "tour-raizes-coloniais"
        ]
    },
    "museu-fioreze": {
        name: "Museu Fioreze",
        tours: [
            "tour-raizes-coloniais"
        ]
    },
    "cantina-colonial-ilha-bela": {
        name: "Cantina Colonial Ilha Bela",
        tours: [
            "tour-raizes-coloniais"
        ]
    },
    "cafe-colonial-coelho": {
        name: "Café Colonial Coelho",
        tours: [
            "tour-raizes-coloniais"
        ]
    },
    "praca-theodor-amstad": {
        name: "Praça Theodor Amstad",
        tours: [
            "tour-alemao-e-compras-nova-petropolis"
        ]
    },
    "armazem-da-rosa-mosqueta": {
        name: "Armazém da Rosa Mosqueta",
        tours: [
            "tour-alemao-e-compras-nova-petropolis"
        ]
    },
    "parque-aldeia-do-imigrante": {
        name: "Parque Aldeia do Imigrante",
        tours: [
            "tour-alemao-e-compras-nova-petropolis"
        ]
    },
    "praca-das-flores-e-labirinto-verde": {
        name: "Praça das Flores e Labirinto Verde",
        tours: [
            "tour-alemao-e-compras-nova-petropolis"
        ]
    },
    "couros-e-malhas": {
        name: "Couros e Malhas",
        tours: [
            "tour-alemao-e-compras-nova-petropolis"
        ]
    },
    "canion-fortaleza": {
        name: "Cânion Fortaleza",
        tours: [
            "tour-canion-itaimbezinho"
        ]
    },
    "portico-normando": {
        name: "Pórtico estilo normando (via Taquara)",
        tours: [
            "tour-templo-budista-e-compras-em-igrejinha"
        ]
    },
    "templo-budista-khadroling": {
        name: "Templo Budista KhadroLing",
        tours: [
            "tour-templo-budista-e-compras-em-igrejinha"
        ]
    },
    "lojas-couro-e-calcados": {
        name: "Lojas de couro e calçados",
        tours: [
            "tour-templo-budista-e-compras-em-igrejinha"
        ]
    },
    "panoramico-poa": {
        name: "Passeio panorâmico por Porto Alegre",
        tours: [
            "tour-city-poa"
        ]
    },
    "margs": {
        name: "Museu de Arte do RS (MARGS)",
        tours: [
            "tour-city-poa"
        ]
    },
    "memorial-rs": {
        name: "Memorial do RS",
        tours: [
            "tour-city-poa"
        ]
    },
    "centro-cultural-santander": {
        name: "Centro Cultural Santander",
        tours: [
            "tour-city-poa"
        ]
    },
    "casa-de-cultura-mario-quintana": {
        name: "Casa de Cultura Mário Quintana",
        tours: [
            "tour-city-poa"
        ]
    },
    "praca-matriz": {
        name: "Praça da Matriz",
        tours: [
            "tour-city-poa"
        ]
    },
    "catedral-metropolitana-poa": {
        name: "Catedral Metropolitana",
        tours: [
            "tour-city-poa"
        ]
    },
    "teatro-sao-pedro": {
        name: "Teatro São Pedro",
        tours: [
            "tour-city-poa"
        ]
    },
    "palacio-farroupilha": {
        name: "Palácio Farroupilha",
        tours: [
            "tour-city-poa"
        ]
    },
    "palacio-piratini": {
        name: "Palácio Piratini",
        tours: [
            "tour-city-poa"
        ]
    },
    "usina-do-gasometro": {
        name: "Usina do Gasômetro",
        tours: [
            "tour-city-poa"
        ]
    },
    "parque-marinha-do-brasil": {
        name: "Parque Marinha do Brasil",
        tours: [
            "tour-city-poa"
        ]
    },
    "orla-do-guaiba": {
        name: "Orla do Guaíba",
        tours: [
            "tour-city-poa"
        ]
    },
    "parque-harmonia": {
        name: "Parque Harmonia",
        tours: [
            "tour-city-poa"
        ]
    },
    "parque-farroupilha-redencao": {
        name: "Parque Farroupilha (Redenção)",
        tours: [
            "tour-city-poa"
        ]
    },
    "parque-moinhos-de-vento-parcao": {
        name: "Parque Moinhos de Vento (Parcão)",
        tours: [
            "tour-city-poa"
        ]
    },
    "estadio-beira-rio": {
        name: "Estádio Gigante do Beira Rio",
        tours: [
            "tour-city-poa"
        ]
    },
    "arena-do-gremio": {
        name: "Arena do Grêmio",
        tours: [
            "tour-city-poa"
        ]
    },
    "maria-fumaca": {
        name: "Passeio de Maria Fumaça",
        tours: [
            "tour-uva-e-vinho"
        ]
    },
    "vinicolas-regiao-uva-e-vinho": {
        name: "Visitação às Vinícolas da região",
        tours: [
            "tour-uva-e-vinho"
        ]
    },
    "varejo-tramontina": {
        name: "Varejo Tramontina",
        tours: [
            "tour-uva-e-vinho"
        ]
    },
}