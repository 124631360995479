export const tourUvaEVinho= {
    name: "Tour Uva e Vinho",
    slug: "tour-uva-e-vinho",
    description: "Passeio de Maria Fumaça, visitação às Vinícolas da região e ao Varejo Tramontina.\n" +
        "Uma viagem de trem ao passado, com muita diversão para você e sua família.\n",
    rules: "Não está incluso no valor os ingressos dos parques e a alimentação, mas a agência pode realizar as reservas no restaurante de sua preferência.",
    poi: [
        "maria-fumaca",
        "vinicolas-regiao-uva-e-vinho",
        "varejo-tramontina"
    ],
    opt: [
        [

        ]
    ]
}