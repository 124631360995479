import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sobre',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/tours',
    name: 'tours',
    component: () => import(/* webpackChunkName: "tour" */ '../views/TourView.vue')
  },
  {
    path: '/tours/:slug',
    name: 'tour',
    component: () => import(/* webpackChunkName: "tour" */ '../views/TourView.vue')
  },
  {
    path: '/contato/',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/uikit',
    name: 'uikit',
    component: () => import(/* webpackChunkName: "uikit" */ '../views/UIKit.vue')
  },
  {
    path: '/content',
    name: 'content',
    component: () => import(/* webpackChunkName: "content" */ '../views/Content.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

export default router
