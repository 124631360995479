export const tourCanionItaimbezinho= {
    name: "Tour Cânion Itaimbezinho",
    slug: "tour-canion-itaimbezinho",
    description: "Aventura no Cânion Fortaleza",
    poi: [
        "canion-fortaleza"
    ],
    opt: [
        [

        ]
    ]
}