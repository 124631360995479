import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/theme.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faInstagram, faFacebookF, faLinkedinIn, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap'
import {Content} from "@/app-modules/cms/Content";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCRbnpJnIqyl3BFOCbQYS8zlnnn5FltL70",
    authDomain: "smartprimetur.firebaseapp.com",
    projectId: "smartprimetur",
    storageBucket: "smartprimetur.appspot.com",
    messagingSenderId: "177906697801",
    appId: "1:177906697801:web:9fd73b9394075e17fde198",
    measurementId: "G-5C3PSRQ8DX"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

library.add(fas, faInstagram, faFacebookF, faLinkedinIn, faTwitter, faWhatsapp)

const app = createApp(App)
    .use(store)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
const rootContainer = '#app'

export const cmsContent = new Content();
app.mount(rootContainer)

