export const tourCityPOA= {
    name: "City Tour Porto Alegre",
    slug: "tour-city-poa",
    description: "",
    poi: [
        "panoramico-poa",
        "margs",
        "memorial-rs",
        "centro-cultural-santander",
        "casa-de-cultura-mario-quintana",
        "praca-matriz",
        "catedral-metropolitana-poa",
        "teatro-sao-pedro",
        "palacio-farroupilha",
        "palacio-piratini",
        "usina-do-gasometro",
        "parque-marinha-do-brasil",
        "orla-do-guaiba",
        "parque-harmonia",
        "parque-farroupilha-redencao",
        "parque-moinhos-de-vento-parcao",
        "estadio-beira-rio",
        "arena-do-gremio",
    ],
    opt: [
        [

        ]
    ]
}