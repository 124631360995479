export const tourGramadoCanela= {
    name: "Tour Gramado e Canela",
    slug: "tour-gramado-canela",
    description: "Panorâmico pela cidade de Canela e Gramado com degustação de queijos e vinhos.",
    poi: [
        "portico-bavaro",
        "lago-negro",
        "mini-mundo",
        "lago-joaquina-rita-bier",
        "monumento-kikito",
        "fabricas-de-chocolate",
        "mundo-a-vapor",
        "catedral-de-pedra",
        "igreja-sao-pedro",
        "cascata-do-caracol",
        "bondinhos-aereos"
    ],
    opt: [
        [
            "cascata-do-caracol",
            "bondinhos-aereos"
        ]
    ]
}