export const cafeColonial= {
    name: "Café Colonial",
    slug: "cafe-colonial",
    description: "Variedade de delícias tradicionais",
    poi: [
        "cul-colonial-serra"
    ],
    opt: [
        [

        ]
    ]
}