export const noiteGaucha = {
    name: "Noite Gaúcha",
    slug: "noite-gaucha",
    description: "Noite temática com o melhor da cultura tradicional gaúcha na churrascaria Garfo e Bombacha.",
    poi: [
        "garfo-e-bombacha"
    ],
    opt: [
        []
    ]
}