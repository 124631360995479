export const tourRaizesColoniais= {
    name: "Tour Raízes Coloniais",
    slug: "tour-raizes-coloniais",
    description: "",
    rules: "Não está incluso no valor os ingressos dos parques e a alimentação.",
    poi: [
        "casa-centenaria",
        "moinho-cavichion",
        "ervateira-marcon",
        "museu-fioreze",
        "cantina-colonial-ilha-bela",
        "cafe-colonial-coelho"
    ],
    opt: [
        [

        ]
    ]
}