export const noiteSuica= {
    name: "Noite Suíça",
    slug: "noite-suica",
    description: "Uma noite especial com a melhor sequência de fondue da Serra.",
    poi: [
        "seq-foundue-serra"
    ],
    opt: [
        [

        ]
    ]
}