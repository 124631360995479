export const tourTemploBudistaEComprasEmIgreijinha= {
    name: "Tour Templo Budista e Compras em Igrejinha",
    slug: "tour-templo-budista-e-compras-em-igrejinha",
    description: "",
    poi: [
        "portico-normando",
        "templo-budista-khadroling",
        "lojas-couro-e-calcados"
    ],
    opt: [
        [

        ]
    ]
}