export const links = {
    "Header_Button_1": "route:home,hash:#services",
    "calendly": "https://calendly.com/moat-sales",
    "facebook": "https://www.facebook.com/yourfacebooklink",
    "instagram": "https://www.instagram.com/yourinstagramlink",
    "linkedin": "https://www.linkedin.com/company/moat-builders-ai/",
    "twitter": "https://twitter.com/yourtwitterlink",
    "NavBar_brand": "route:home",
    "NavBar_NavItem_1": "route:home,hash:#services",
    "NavBar_NavItem_2": "route:home,hash:#how-it-works",
    "NavBar_NavItem_3": "route:careers",
    "careers": "route:careers",
    "contact": "route:contact",
    "contact_plan_1": "route:contact,params:opt=1",
    "contact_plan_2": "route:contact,params:opt=2",
    "contact_plan_3": "route:contact,params:opt=3",
    "NavBar_Button": "route:home,hash:#plans",
    "Footer_Careers": "route:careers",
    "Footer_How_it_works": "route:home,hash:#how-it-works",
    "Footer_Plans": "route:home,hash:#plans",
    "Footer_Service": "route:home,hash:#services",
    "Footer_Whats_CTOaaS": "route:home,hash:#solutions-cto-as-a-service"
} as any;