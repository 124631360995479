import { tourGramadoCanela } from "./pt/roteiros/tourGramadoCanela"
import { noiteGaucha } from "./pt/roteiros/noiteGaucha"
import { noiteSuica } from "./pt/roteiros/noiteSuica"
import { noiteAlema } from "./pt/roteiros/noiteAlema"
import { cafeColonial } from "./pt/roteiros/cafeColonial"
import { transferTranslado } from "./pt/roteiros/transferTranslado"
import { tourParques } from "./pt/roteiros/tourParques"
import { tourRaizesColoniais } from "./pt/roteiros/tourRaizesColoniais"
import { tourAlemaoEComprasNovaPetropolis } from "./pt/roteiros/tourAlemaoEComprasNovaPetropolis"
import { tourCanionItaimbezinho } from "./pt/roteiros/tourCanionItaimbezinho"
import { tourTemploBudistaEComprasEmIgreijinha } from "./pt/roteiros/tourTemploBudistaEComprasEmIgreijinha"
import { tourCityPOA } from "./pt/roteiros/tourCityPOA"
import { tourUvaEVinho } from "./pt/roteiros/tourUvaEVinho"
import { poi } from "@/app-modules/cms/pt/poi";
import { media } from "@/app-modules/cms/pt/media";

export const pt = {
    "404": "404 - Não Encontrado",
    "Header_Headline": "A Serra Gaúcha com o Calor do Atendimento Familiar",
    "Header_Paragraph": "Viva o melhor da serra gaúcha com a segurança e o conforto que só uma agência de turismo familiar pode oferecer",
    "About_Headline": "Somos uma Agência de Turismo Familiar Privativo na Serra Gaúcha",
    "About_Paragraph": "Viva o melhor da serra gaúcha com segurança e conforto que só uma agência de turismo familiar pode oferecer",
    "poi_intro": "Inclui os seguintes locais:",
    "poi_optional": "Opcionais:",
    "or": "ou",
    "FAQ_Headline": "Perguntas Frequentes",
    "FAQ_Question_1_q": "Posso fazer alterações nos roteiros, tours e pacotes?",
    "FAQ_Question_1_a": "Sim! Os roteiros e tours são montados como sugestão para otimizar o seu tempo, porém você tem liberdade de fazer substituições e alterar o itinerario conforme suas preferências.",
    "FAQ_Question_2_q": "Quantos passageiros posso incluir?",
    "FAQ_Question_2_a": "A Smartprime turismo foca em experiências ímpares no turismo privativo com um número máximo de 6 passageiros.",
    "media": {
        ...media
    },
    "POI": {
        ...poi
    },
    "TOURS": {
        "tour-gramado-canela": tourGramadoCanela,
        "noite-gaucha": noiteGaucha,
        "noite-suica": noiteSuica,
        "noite-alema": noiteAlema,
        "cafe-colonial": cafeColonial,
        "transfer-translado": transferTranslado,
        "tour-parques": tourParques,
        "tour-raizes-coloniais": tourRaizesColoniais,
        "tour-alemao-e-compras-nova-petropolis": tourAlemaoEComprasNovaPetropolis,
        "tour-canion-itaimbezinho": tourCanionItaimbezinho,
        "tour-templo-budista-e-compras-em-igrejinha": tourTemploBudistaEComprasEmIgreijinha,
        "tour-city-poa": tourCityPOA,
        "tour-uva-e-vinho": tourUvaEVinho
    }
}