import {pt} from "@/app-modules/cms/pt";
import {links} from "@/app-modules/cms/links";
import {first, forEach, pickBy, size} from 'lodash';
import router from "@/router";
import axios from "axios";
import {PublicConfig} from "@/app-modules/PublicConfig";
import {cmsContent} from "@/main";

export class Content {
    // decide which language to use, defaults to imported en
    content: any;
    links: any;
    locale: string = 'pt';

    constructor() {
        this.content = { pt: pt }
        // this.links = { pt: links }
    }
    async getLinksFromCms(page: number = 1) {
        try {
            const sLinks = await axios.get(`${PublicConfig.cmsUrl}/moat-site-links/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${PublicConfig.cmsReadKey}`
                }
            });
            this.setLinksFromData(sLinks.data)

            if(sLinks.data.meta.pagination.pageCount > page) {
                await this.getContentFromCms(page + 1)
            }
        } catch (e) {
            console.error(e)
            this.links =  { en: links }
        }
    }

    async getContentFromCms(page: number = 1) {
        try {
            const sText = await axios.get(`${PublicConfig.cmsUrl}/moat-site-contents/?pagination[pageSize]=100&pagination[page]=${page}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${PublicConfig.cmsReadKey}`
                }
            });
            this.setContentFromData(sText.data)

            if(sText.data.meta.pagination.pageCount > page) {
                await this.getContentFromCms(page + 1)
            }
        } catch (e) {
            console.error(e)
            this.content = { pt: pt }
        }
    }

    setContentFromData(content: any) {
        forEach(content.data, (value, key) => {
            let locale = value['attributes']['locale'] || 'none'
            const slug = value['attributes']['slug']
            const text = value['attributes']['text']

            if (!this.content.hasOwnProperty(<string | number | symbol> locale))  this.content[locale] = {}
            this.content[locale][slug] = text || ''
        })
    }

    setLinksFromData(content: any) {
        forEach(content.data, (value, key) => {
            let locale = value['attributes']['locale'] || 'none'
            const slug = value['attributes']['slug']
            const text = value['attributes']['text']

            if (!this.links.hasOwnProperty(<string | number | symbol> locale))  this.links[locale] = {}
            this.links[locale][slug] = text || ''
        })
    }

    static getTextById(id: string): string {
        try {
            return cmsContent.content[cmsContent.locale][`${id}`]
        } catch (e) {
            console.error(e)
            return ''
        }
    }

    static getLinkById(id: string): string {
        try {
            return cmsContent.links[cmsContent.locale][`${id}`]
        } catch (e) {
            console.error(e)
            return ''
        }
    }

    static countKeysByRegex(regex: RegExp): number {
        try {
            return size(Object.keys(cmsContent.content[cmsContent.locale]).filter((blockKey) => blockKey.match(regex)))
        } catch (e) {
            console.error(e)
            return 0
        }
    }

    static retrieveKeysByRegex(regex: RegExp): string[] {
        try {
            return Object.keys(cmsContent.content[cmsContent.locale]).filter((blockKey) => blockKey.match(regex))
        } catch (e) {
            console.error(e)
            return []
        }
    }

    static search(id: string, text: string) {
        if (!id && !text) return cmsContent.content[cmsContent.locale];
        return pickBy(cmsContent.content[cmsContent.locale], (value, key) => {
            if (id && !key.toLowerCase().includes(id.toLowerCase())) return false;
            return !(text && !JSON.stringify(value).toLowerCase().includes(text.toLowerCase()));
        });
    }

    static async navigate(id: string) {
        const target = this.getLinkById(id)

        try {
            const config = {} as any
            const rawParams = target.split(',')
            // check if target contains hash:
            if (target.includes('hash:')) {
                // find hash in params
                config.hash = rawParams.find((param: string) => param.includes('hash:'))?.split(':')[1]
            }
            // check if target contains params
            if (target.includes('params:')) {
                // create a new param for each param using the format param:foo=bar
                const params = rawParams.filter((param: string) => param.includes('params:'))?.map((param: string) => {
                    const [key, value] = param.split(':')[1].split('=')
                    return {
                        [key]: value
                    }
                })
                config.params = first(params)
            }
            // check if target contains route:
            if (target.includes('route:')) {
                // find route in params
                config.name = rawParams.find((param: string) => param.includes('route:'))?.split(':')[1]
                await router.push(config);
                return
            }
        } catch (e) {
            console.error(e)
        }

        if(target) {
            // if no route: or hash: found, just navigate to target, check if it is a valid url
            if (!target.includes('http')) {
                console.error('Invalid target: ' + target)
                return
            }
            window.location.href = target
        } else {
            window.location.href = id
        }
    }
}

export const t = (id: string) => {
    return Content.getTextById(id);
}

export const l = async (id: string) => {
    await Content.navigate(id)
}